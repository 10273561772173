import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Layout from '../../../components/layout'
import Seo from "../../../components/seo"

export default function FGM() {
    return (
        <Layout bodyClass="comics">
            <Seo title="AWRA COMICS - End FGM" />
            <article className='container'>
                <header className='article-header'>
                    <h1>FGM is a crime.</h1>
                </header>
                <section className='grid'>
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGE1.jpg?ssl=1" alt="I was about ten years old when I was cut." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGE2.jpg?ssl=1" alt="I was pinned down by my mother and grand-mother..." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGE3.jpg?ssl=1" alt="They thought it would be a good way to keep me 'honorable' and 'pure'." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGE4.jpg?ssl=1" alt="Will I have to go through it, Mom?" placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGE5.jpg?ssl=1" alt="My dear daughter, this ends with me. FGM is a crime. It Goes against all religions. You are perfect as you are." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} className='-full' />
                </section>
                <section className='grid'>
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGA2.jpg?ssl=1" alt="I was pinned down by my mother and grand-mother..." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGA1.jpg?ssl=1" alt="I was about ten years old when I was cut." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGA4.jpg?ssl=1" alt="Will I have to go through it, Mom?" placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGA3.jpg?ssl=1" alt="They thought it would be a good way to keep me 'honorable' and 'pure'." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGA5.jpg?ssl=1" alt="My dear daughter, this ends with me. FGM is a crime. It Goes against all religions. You are perfect as you are." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} className='-full' />
                </section>
            </article>
        </Layout>
    )
}
